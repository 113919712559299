<template>
    <div class="card col-md-8 col-lg-6 col-xl-5 col-xl2-4 mx-auto px-0">
        <div class="card-body">
            <h4 class="mt-5 mb-4 lead font-weight-medium">Sign In</h4>
            <h5 class="font-weight-light mb-3">Enter your email address and password to access
                account.</h5>
            <form id="login-form" class="pt-3 pb-xl2-4"
                  @submit.prevent="handleSubmit">
                <Input class-name="form-control-lg"
                       name="email"
                       type="email"
                       label="Email address"
                       placeholder="Enter your email"
                       autocomplete="username"
                       @change="handleChange"
                       :value="email"
                       :v="$vServer.email || $v.email"/>
                <div class="position-relative mb-4 mb-lg-5 mt-4">
                    <router-link :to="{name: 'forgot-password'}" id="forget-password"><small>Forgot your
                        password?</small></router-link>
                    <Input class-name="form-control-lg"
                           name="password"
                           type="password"
                           label="Password"
                           autocomplete="current-password"
                           placeholder="Enter your password (6 or more characters)"
                           @change="handleChange"
                           :value="password"
                           :v="$vServer.password || $v.password"/>
                </div>
                <div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
                    <Checkbox class="mb-4 mb-lg-2"
                              name="remember_me"
                              :value="1"
                              :checked="remember_me > 0"
                              label="Remember me"
                              @change="handleChange"
                    />
                    <button class="btn btn-primary btn-lg py-2 px-5">Log In</button>
                </div>
            </form>
            <hr class="my-5">
            <p class="text-center">
                Don't have an account?
                <router-link :to="{name: 'register'}">Sign Up</router-link>
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Input from "@/components/form/Input";
import {email, minLength, required} from "vuelidate/lib/validators";
import {scrollToError} from "@/helpers";
import {LOGIN_URL} from "@/config/api";
import {auth_route} from "@/config/auth";
import Checkbox from "@/components/form/Checkbox";

export default {
    name: "LoginForm",
    components: {Checkbox, Input},
    data: () => ({
        submitted: false,
        email: null,
        password: null,
        remember_me: null,
    }),
    validations: {
        email: {required, email},
        password: {
            required,
            minLength: minLength(6),
        },
    },
    computed: {
        ...mapGetters({
            '$vServer': 'serverNotice/errors'
        }),
    },
    methods: {
        handleChange({name, value}) {
            if (this[name] !== value) {
                this[name] = value;
            }
        },
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                this.login();
            }
        },
        async login() {
            if (this.submitted) return false;

            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');
            try {
                this.$root.$preloader.start();

                const {data, status} = await this.$http.post(LOGIN_URL, this.$data, {
                    timeout: 10000,
                });

                this.$store.dispatch('auth/saveToken', {
                    token: data.access_token,
                    remember: this.remember_me,
                });

                await this.$store.dispatch('auth/fetchUser')

                this.$store.dispatch('serverNotice/setNotice', {
                    message: 'Login was successfull',
                    status: status,
                });

                this.redirect()

            } catch (e) {
                const {data, status} = e.response;
                if (!data.status)
                    data.status = status;
                this.$store.dispatch('serverNotice/setNotice', data);
                this.resetForm();
                this.$root.$preloader.finish();

                this.$nextTick(() => scrollToError(this.$el))
            }

        },
        resetForm() {
            this.email = null;
            this.password = null;
            this.submitted = false;
            this.$v.$reset();
        },
        redirect() {
            const intendedUrl = this.$cookies.get('intended_url')
            if (intendedUrl) {
                this.$cookies.remove('intended_url')
                this.$router.push({path: intendedUrl})
            } else {
                this.$router.push({name: auth_route})
            }
        }
    },

}
</script>
<style lang="scss">
#login-form {
    .form-control-lg {
        @include font-size($input-font-size);
        height: 50px;
    }
}

#forget-password {
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 1px;
    color: $body-color;
    font-size: $h5-font-size;
}
</style>
